export const ImageToTextBannerData = [
    {
      text: "Extract text from images and PDFs quickly – compatible with JPG, PNG, WEBP, HEIC, PDF, and more.",
    },
    {
      text: "Digitize notes, printed materials, and scanned files with ease.",
    },
    {
      text: "Compare two AI-powered OCR results side-by-side for enhanced accuracy.",
    },
    {
      text: "Prepare extracted content for editing, translation, or localization.",
    },
    {
      text: "Boost productivity by eliminating the need for manual data entry.",
    },
    
  ];
  export const anonymizationBannerData = [
    {
      text: "<span class='font-bold'>Anonymize structured or unstructured text:</span> Easily scrub PII from standard text files, Word documents, and PDFs.",
    },
    {
      text: "<span class='font-bold'>Insert automatic placeholders: </span>Replace sensitive information like names, phone numbers, or addresses without losing context.",
    },
    {
      text: "<span class='font-bold'>Redact PDF files: </span> Perform Document Redaction on PDFs in seconds with a simple upload.",
    },
    {
      text: "<span class='font-bold'>Maintain accuracy: </span>Retain key information and data structure through precise placeholder insertion, supporting ongoing analysis or review.",
    },
    {
      text: "<span class='font-bold'>Save time and resources: </span>Eliminate manual redaction tasks and reduce the risk of human error with AI-powered data redaction.",
    },
    {
      text: "<span class='font-bold'>Get started instantly: </span> Enjoy no sign-up and no installation. Simply upload and anonymize.",
    },
    
  ];